module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/@bonobolabs/gatsby-remark-images-custom-widths","id":"3f889dc8-8fe6-5055-8b15-ea320f441048","name":"@bonobolabs/gatsby-remark-images-custom-widths","version":"0.0.6","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/plugins/gatsby-remark-unwrap-inlinecode/index.js"},{"resolve":"gatsby-remark-embed-video","options":{"width":720,"ratio":1.77,"related":false,"noIframeBorder":true,"urlOverrides":[{"id":"youtube"}],"containerClass":"embedVideo-container"}},{"resolve":"@bonobolabs/gatsby-remark-images-custom-widths","options":{"maxWidth":720,"showCaptions":true,"markdownCaptions":false}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: var(--space-md)"}},{"resolve":"gatsby-remark-prismjs"},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-footnotes","options":{"footnoteBackRefPreviousElementDisplay":"inline","footnoteBackRefDisplay":"inline","footnoteBackRefInnerText":"&#8635;","footnoteBackRefAnchorStyle":"text-decoration: none;","useFootnoteMarkerText":false}}]},
    },{
      plugin: require('../node_modules/@bonobolabs/gatsby-remark-images-custom-widths/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jacob Deane","short_name":"JD","start_url":"/","background_color":"#ffffff","theme_color":"#808080","display":"minimal-ui","icon":"static/favicon.svg","icon_options":{"purpose":"maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9979729a29b2958f4da89f7a4bc590e2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
